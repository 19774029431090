import auth from "@/services/auth.service";
import config from "@/app.config";
import ModuleGenerator from "@/components/base/ModuleGenerator";

export default class Folder {
  static from_api(data) {
    let instance = new this();
    for (const [key, value] of Object.entries(data)) {
      // if children
      if (key === "children") {
        instance.children = value.map((child) => Folder.from_api(child));
      } else {
        instance[key] = value;
      }
    }
    instance.isRoot = false;
    return instance;
  }

  static fetchAll(project_id) {
    return auth
      .get(`${config.endpoints.PROJECTS}${project_id}/datapool/`)
      .then((response) => {
        return response.data.results.map((result) => {
          return Folder.from_api(result);
        });
      });
  }
  prepareForApi() {
    let formData = new FormData();
    formData.append("name", this.name);
    if (this.parent.isRoot) {
      this.parent.id = this.project.folder;
    }
    formData.append("parent", this.parent.id);
    return formData;
  }
  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/datapool/`,
        this.prepareForApi()
      )
      .then((response) => {
        return Folder.from_api(response.data);
      });
  }
  prepareFolderForApi() {
    let formData = new FormData();
    formData.append("pk", this.id);
    formData.append("name", this.name);
    formData.append("parent", this.parent);
    return formData;
  }
  update() {
    return auth
      .put(
        `${config.endpoints.PROJECTS}${this.project.id}/datapool/${this.id}/`,
        this.prepareFolderForApi()
      )
      .then((response) => {
        return Folder.from_api(response.data);
      });
  }
  delete() {
    return auth.delete(
      `${config.endpoints.PROJECTS}${this.project.id}/datapool/${this.id}/`,
      this.prepareFolderForApi()
    );
  }
}

export class File {
  constructor(file, project, folder, folderTitle) {
    this.file = file;
    this.project = project;
    this.folderTitle = folderTitle;
    this.folder = folder;
  }

  static from_api(data) {
    let instance = new this();
    for (const [key, value] of Object.entries(data)) {
      instance[key] = value;
    }
    return instance;
  }

  prepareForApi() {
    let formData = new FormData();
    if (this.folder.isRoot) {
      this.folder.id = this.project.folder;
    }
    formData.append(this.file.name, this.file.file);
    formData.append("original_filename", this.file.name);
    formData.append("folder", this.folder.id);
    return formData;
  }

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/files/`,
        this.prepareForApi()
      )
      .then((response) => {
        return File.from_api(response.data);
      });
  }

  prepareFileForApi() {
    let formData = new FormData();
    formData.append("pk", this.id);
    formData.append("name", this.name);
    formData.append("relation", JSON.stringify(this.relation));
    formData.append("folder", this.folder);
    return formData;
  }
  update() {
    return auth
      .patch(
        `${config.endpoints.PROJECTS}${this.project.id}/files/${this.id}/`,
        this.prepareFileForApi()
      )
      .then((response) => {
        return File.from_api(response.data);
      });
  }
  delete() {
    return auth.delete(
      `${config.endpoints.PROJECTS}${this.project.id}/files/${this.id}/`,
      this.prepareFileForApi()
    );
  }
  get thumbnailUrl() {
    if (this.thumbnail) {
      if (this.thumbnail.includes("http")) {
        return this.thumbnail;
      }
      return `${config.BACKEND_URL}${this.thumbnail}`;
    }
    return "";
  }
  static fetchAll(project_id) {
    return auth
      .get(`${config.endpoints.PROJECTS}${project_id}/files/`)
      .then((response) => {
        return response.data.results.map((result) => {
          return File.from_api(result);
        });
      });
  }

  get url() {
    if (this.file.includes("http")) {
      return this.file;
    }
    return `${config.BACKEND_PORTAL}${this.file}`;
  }

  getPreview() {
    window.open(this.url, "_blank");
  }

  getDownload() {
    fetch(this.url, {
      method: "get",
    })
      .then((reponse) => reponse.blob())
      .then((reponse) => {
        const aElement = document.createElement("a");
        aElement.setAttribute("download", this.original_filename);
        const href = URL.createObjectURL(reponse);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }
  isImage() {
    // if image is in mime_type
    return this.mime_type.includes("image");
  }
  getName() {
    return this.name || this.original_filename;
  }
}

export class UnsortedFile extends File {
  constructor(file, project, folder, folderTitle, fileGroup, jobId) {
    super(file, project, folder, folderTitle);
    this.fileGroup = fileGroup;
    this.jobId = jobId;
  }

  prepareForApi() {
    let formData = new FormData();
    formData.append(this.file.name, this.file.file);
    formData.append("original_filename", this.file.name);
    formData.append("folder", this.folderTitle);
    if (this.fileGroup) formData.append("group", this.fileGroup);
    if (this.jobId) formData.append("job_id", this.jobId);
    return formData;
  }

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/files/unsorted/?id=${this.file.id}-${this.file.batchId}`,
        this.prepareForApi()
      )
      .then((response) => {
        return File.from_api(response.data);
      });
  }
}

export class NodeFile extends File {
  constructor(file, project, folder, folderTitle, node) {
    super(file, project, folder, folderTitle);
    this.node = node;
  }

  prepareForApi() {
    let formData = new FormData();
    formData.append(this.file.name, this.file.file);
    formData.append("original_filename", this.file.name);

    if (this.node !== undefined) {
      let relation = {
        id: this.node.id,
        type: this.node.type,
        project: this.node.project.id,
        title: this.node.title,
        owner: this.node.owner,
        detail_url: this.node.detail_url,
      };
      formData.append("relation", JSON.stringify(relation));
    }

    return formData;
  }

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/files/node/`,
        this.prepareForApi()
      )
      .then((response) => {
        return File.from_api(response.data);
      });
  }

  static fetchUrl(url) {
    return auth.get(`${config.BACKEND_PORTAL}` + url).then((response) => {
      return response.data.map((result) => {
        return File.from_api(result);
      });
    });
  }
}

export const DatapoolStoreModule = new ModuleGenerator(
  {
    folders: {
      list: Folder.fetchAll,
      model: Folder,
    },
    files: {
      list: File.fetchAll,
      model: File,
    },
  },
  { folders: [], files: [] },
  {
    state: {
      folderPath: [
        {
          name: "Alle Ordner",
          isRoot: true,
        },
      ],
      currentFolders: [],
      currentFiles: [],
    },
    getters: {
      folderPath: (state) => state.folderPath,
      currentFolders: (state) => state.currentFolders,
      lastModifiedFiles: (state) => {
        return state.files
          .filter((file) => {
            return file.modified_at;
          })
          .sort((a, b) => {
            return new Date(b.modified_at) - new Date(a.modified_at);
          })
          .slice(0, 6);
      },
      currentFiles: (state) => state.currentFiles,
      getFolderById: (state) => (id) => {
        let folder = state.folders.find((folder) => {
          return folder.id == id;
        });
      },
    },
    mutations: {
      setCurrentFolders(state, payload) {
        state.currentFolders = payload;
      },
      setCurrentFiles(state, payload) {
        state.currentFiles = payload;
      },
      setFolderPath(state, payload) {
        state.folderPath = payload;
      },
      pushFolderToPath(state, payload) {
        state.folderPath.push(payload);
      },
      popFolderFromPath(state) {
        state.folderPath.pop();
      },
      removeFile(state, index) {
        state.files.splice(index, 1);
      },
      resetState(state) {
        state.folders = [];
        state.files = [];
        state.currentFolders = [];
        state.currentFiles = [];
        state.folderPath = [
          {
            name: "Alle Ordner",
            isRoot: true,
          },
        ];
        state.filesListIsLoaded = false;
        state.filesLoading = false;
        state.foldersListIsLoaded = false;
        state.foldersLoading = false;
      },
    },
    actions: {
      appendFiles({ commit }, payload) {
        commit("appendFiles", { objects: payload });
      },
      pushPathOnly(context, folder) {
        context.commit("pushFolderToPath", folder);
      },
      pushFolderToPath(context, folder) {
        context.commit("pushFolderToPath", folder);
        context.commit("setCurrentFolders", folder.children);
      },
      popFolderFromPath(context) {
        context.commit("popFolderFromPath");
      },
      removeFile(context, file) {
        let index = context.state.files.indexOf(file);
        if (index != -1) context.commit("removeFile", index);
      },
      // get folder by id
      getFolderById(context, id) {
        return context.getters.getFolderById(id);
      },
      setCurrentFolders(context, folders) {
        context.commit("setCurrentFolders", folders);
      },
      setCurrentFiles(context, files) {
        context.commit("setCurrentFiles", files);
      },
      resetState(context) {
        context.commit("resetState");
      },
      resetFolders(context, folder) {
        if (folder.isRoot) {
          context.commit("setCurrentFolders", context.state.folders);
          context.commit("setFolderPath", [
            {
              name: "Alle Ordner",
              isRoot: true,
              children: context.state.folders,
            },
          ]);
        } else {
          // remove all folders after the current folder in folderPath
          context.commit("setCurrentFolders", folder.children);
          let folderPath = context.state.folderPath;
          let index = folderPath.findIndex((f) => f.name === folder.name);
          context.commit("setFolderPath", folderPath.slice(0, index + 1));
        }
      },
    },
  }
).module();

export const DatapoolModalStoreModule = {
  namespaced: true,
  state: {
    modalStatus: false,
    node: null,
    action: null,
  },
  getters: {
    modalStatus: (state) => state.modalStatus,
    node: (state) => state.node,
    action: (state) => state.action,
  },
  mutations: {
    setModalStatus(state, payload) {
      state.modalStatus = payload;
    },
    setNode(state, payload) {
      state.node = payload;
    },
    setAction(state, payload) {
      state.action = payload;
    },
  },
  actions: {
    openModal(context, payload) {
      context.commit("setNode", payload.node);
      context.commit("setAction", payload.action);
      context.commit("setModalStatus", true);
    },
    closeModal(context) {
      context.commit("setModalStatus", false);
      context.commit("setNode", null);
      context.commit("setAction", { icon: "", name: "" });
    },
  },
};
