import { LWLModel } from "./base.js";
import config from "@/app.config";
import auth from "@/services/auth.service";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
  fetchByIds,
} from "@/components/base/ModuleGenerator";
import { useToast } from "vue-toastification";
import { showErrorToast } from "@/utils/ui.js";
import store from "@/store/index.js";

export default class Project extends LWLModel {
  static ENDPOINT = "project";
  static VERBOSE_NAME = "Projekt";
  static VERBOSE_NAME_PLURAL = "Projekte";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, `${config.endpoints.ROOT}`, param);
  }

  static async getBySlug(project_slug) {
    return auth
      .get(`${config.endpoints.PROJECTS}?slug=${project_slug}`)
      .then((response) => {
        return Project.from_api(response.data.results[0]);
      });
  }

  getBuilder() {
    return new Builder(`${config.endpoints.PROJECTS}${this.id}/builder/`);
  }

  getOwner() {
    return store.getters["accounts/usersById"](this.owner);
  }

  getAllUsers() {
    const loggedInUser = store.getters["auth/user"];
    const userIds = Array.from(
      new Set(this.users).add(this.owner).add(loggedInUser.id)
    );
    return store.getters["accounts/usersByIds"](userIds);
  }

  getAllComponentOwners() {
    const userIds = Array.from(new Set(this.component_owners).add(this.owner));
    return store.getters["accounts/usersByIds"](userIds);
  }

  getAllProviders() {
    const providerIds = Array.from(new Set(this.providers));
    return store.getters["providers/providersByIds"](providerIds);
  }

  getAllServiceProviders() {
    const serviceProviderIds = Array.from(new Set(this.service_providers));
    return store.getters["providers/serviceProvidersByIds"](serviceProviderIds);
  }

  getJobStats() {
    return this.job_status_stats;
  }

  getDefaultColorCode() {
    return this.default_color_code;
  }

  getColorForLayer(layername) {
    let found = this.layer_colors.find((color) => {
      let re = new RegExp(color.layer, "i");
      return Boolean(layername.match(re));
    });
    return found ? found.color.web : null;
  }

  isLayerDashed(layername) {
    let found = this.layer_colors.find((color) => {
      let re = new RegExp(color.layer, "i");
      return Boolean(layername.match(re));
    });
    return found ? found.is_dashed : false;
  }

  getLayerGroup(layername) {
    let found = this.layer_colors.find((color) => {
      let re = new RegExp(color.layer, "i");
      return Boolean(layername.match(re));
    });
    return found ? found.layer_group : null;
  }
}

export class Builder {
  constructor(endpoint) {
    self.endpoint = endpoint;
  }

  async connectNodesWithPipe(data) {
    return auth
      .post(self.endpoint, {
        action: "connect_nodes_with_pipe",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async updateCablesInTubes(data) {
    return auth
      .post(self.endpoint, {
        action: "update_cables_in_tubes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async insertCableIntoTubes(data) {
    return auth
      .post(self.endpoint, {
        action: "insert_cable_into_tubes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async insertPipeIntoTubes(data) {
    return auth
      .post(self.endpoint, {
        action: "insert_pipe_into_tubes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async updatePipes(data) {
    return auth
      .post(self.endpoint, {
        action: "update_pipes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async updateBunchConnection(data) {
    return auth
      .post(self.endpoint, {
        action: "update_bunch_connection",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async moveSpliceTrayInNode(data) {
    return auth
      .post(self.endpoint, {
        action: "move_splicetray_in_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async joinCablesInNode(data) {
    return auth
      .post(self.endpoint, {
        action: "join_cables_in_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async splitCableInNode(data) {
    return auth
      .post(self.endpoint, {
        action: "split_cable_in_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async addSpliceTrayToNode(data) {
    return auth
      .post(self.endpoint, {
        action: "add_splicetray_to_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async addSpliceClosureToNode(data) {
    return auth
      .post(self.endpoint, {
        action: "add_spliceclosure_to_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async insertBunchIntoTray(data) {
    return auth
      .post(self.endpoint, {
        action: "insert_bunch_into_tray",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async splitAndConnectTube(data) {
    return auth
      .post(self.endpoint, {
        action: "split_and_connect_tube",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async insertPipeAndConnectTube(data) {
    return auth
      .post(self.endpoint, {
        action: "insert_pipe_and_connect_tube",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async removeTubeFromHousebox(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_tube_from_housebox",
        data: data,
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        showErrorToast(error);
        throw error;
      });
  }

  async spliceFibersInTray(data) {
    return auth
      .post(self.endpoint, {
        action: "splice_fibers_in_tray",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async removeSplicesOfBunchConnectionInTray(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_splices_of_bunch_connection_in_tray",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async addCabinetToNode(data) {
    return auth
      .post(self.endpoint, {
        action: "add_cabinet_to_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async addPatchFieldToCabinet(data) {
    return auth
      .post(self.endpoint, {
        action: "add_patchfield_to_cabinet",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async insertBunchIntoPatchField(data) {
    return auth
      .post(self.endpoint, {
        action: "insert_bunch_into_patchfield",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async removeBunchFromConnector(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_bunch_from_connector",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async connectTubes(data) {
    return auth
      .post(self.endpoint, {
        action: "connect_tubes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async unlinkTubes(data) {
    return auth
      .post(self.endpoint, {
        action: "unlink_tubes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async connectPipes(data) {
    return auth
      .post(self.endpoint, {
        action: "connect_pipes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async unlinkPipes(data) {
    return auth
      .post(self.endpoint, {
        action: "unlink_pipes",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async connectPatchFieldPorts(data) {
    return auth
      .post(self.endpoint, {
        action: "connect_patch_field_ports",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async unlinkPatchFieldPorts(data) {
    return auth
      .post(self.endpoint, {
        action: "unlink_patch_field_ports",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async removeFiberFromPatchfield(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_fiber_from_patchfield",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }

  async updateVNodePosition(data) {
    return auth
      .post(self.endpoint, {
        action: "update_vnode_position",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async removePipeFromNode(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_pipe_from_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async patchTubeConnector(data) {
    // COMMENT should be refactored, when there is an exclusive state for TubeConnectors
    return auth
      .post(self.endpoint, { action: "patch_tube_connector", data: data })
      .then(async (response) => {
        return response;
      });
  }
  async removeSpliceConnectionInTray(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_splice_connection_in_tray",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async updateSpliceConnectionsOfBunchConnection(data) {
    return auth
      .post(self.endpoint, {
        action: "update_splices_of_bunch_connection_in_tray",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async removeFibersOfBunchConnectionInTray(data) {
    return auth
      .post(self.endpoint, {
        action: "remove_fibers_of_bunch_connection_in_tray",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async moveCableInOtherTube(data) {
    return auth
      .post(self.endpoint, {
        action: "move_cable_in_other_tube",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async createNetworkNode(data) {
    return auth
      .post(self.endpoint, {
        action: "create_network_node",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
  async editNetworkNodeType(data) {
    return auth
      .post(self.endpoint, {
        action: "edit_network_node_type",
        data: data,
      })
      .then(async (response) => {
        return response;
      });
  }
}

export const ProjectStoreModule = new SimpleModuleGenerator(
  {
    projects: Project,
  },
  {
    state: {
      project: null,

      fetchedAllImportTypes: false,
      fetchingAllImportTypes: false,
      importTypes: [],

      fetchedAllExportTypes: false,
      fetchingAllExportTypes: false,
      exportTypes: [],
    },
    mutations: {
      setProject(state, payload) {
        state.project = payload.project;
      },

      setImportTypes(state, payload) {
        state.importTypes = payload;
      },
      setResetImportTypes(state) {
        state.importTypes = [];
        state.fetchedAllImportTypes = false;
      },
      setFetchingAllImportTypes(state, payload) {
        state.fetchingAllImportTypes = payload;
      },
      setFetchedAllImportTypes(state, payload) {
        state.fetchedAllImportTypes = payload;
      },

      setExportTypes(state, payload) {
        state.exportTypes = payload;
      },
      setResetExportTypes(state) {
        state.exportTypes = [];
        state.fetchAllExportTypes = false;
      },
      setFetchingAllExportTypes(state, payload) {
        state.fetchingAllExportTypes = payload;
      },
      setFetchedAllExportTypes(state, payload) {
        state.fetchedAllExportTypes = payload;
      },
    },
    actions: {
      fetchProject: ({ commit }, payload) => {
        try {
          if (payload.slug !== null) {
            Project.getBySlug(payload.slug).then(async (response) => {
              if (response.count !== 0) {
                commit("setProject", { project: response });
              } else {
                console.error("Project not found");
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      },
      setCurrentProject: ({ commit }, payload) => {
        try {
          commit("setProject", { project: payload });
        } catch (err) {
          console.log(err);
        }
      },
      fetchAllImportTypes: ({ commit, getters }, payload) => {
        if (
          getters.fetchedAllImportTypes ||
          getters.fetchingAllImportTypes ||
          payload === null
        ) {
          return;
        }

        commit("setFetchingAllImportTypes", true);
        let toast = useToast();
        let toastId = toast.info(`Lade Import Varianten ...`, {
          timeout: false,
        });

        auth
          .get(`${config.endpoints.PROJECTS}${payload}/import_types/`)
          .then(async (response) => {
            if (response.data.import_types.length !== 0) {
              commit("setImportTypes", response.data.import_types);
              commit("setFetchedAllImportTypes", true);
              commit("setFetchingAllImportTypes", false);
              toast.dismiss(toastId);
            }
          })
          .catch((error) => {
            console.error(error);
            commit("setFetchingAllImportTypes", false);
            toast.dismiss(toastId);
          });
      },
      resetImportTypes: ({ commit }) => {
        commit("setResetImportTypes");
      },
      fetchAllExportTypes: ({ commit, getters }, payload) => {
        if (
          getters.fetchedAllExportTypes ||
          getters.fetchingAllExportTypes ||
          payload === null
        ) {
          return;
        }

        commit("setFetchingAllExportTypes", true);
        let toast = useToast();
        let toastId = toast.info(`Lade Export Varianten...`, {
          timeout: false,
        });

        auth
          .get(`${config.endpoints.PROJECTS}${payload}/export_types/`)
          .then(async (response) => {
            if (response.data.export_types.length !== 0) {
              commit("setExportTypes", response.data.export_types);
              commit("setFetchedAllExportTypes", true);
              commit("setFetchingAllExportTypes", false);
              toast.dismiss(toastId);
            }
          })
          .catch((error) => {
            console.error(error);
            commit("setFetchingAllExportTypes", false);
            toast.dismiss(toastId);
          });
      },
      resetExportTypes: ({ commit }) => {
        commit("setResetExportTypes");
      },
    },
    getters: {
      project(state) {
        return state.project;
      },
      getProjectBySlug: (state) => (slug) => {
        return state.projects.find((project) => project.slug === slug);
      },
      fetchingAllImportTypes: (state) => state.fetchingAllImportTypes,
      fetchedAllImportTypes: (state) => state.fetchedAllImportTypes,
      importTypes: (state) => state.importTypes,

      fetchingAllExportTypes: (state) => state.fetchingAllExportTypes,
      fetchedAllExportTypes: (state) => state.fetchedAllExportTypes,
      exportTypes: (state) => state.exportTypes,
    },
  }
).module();

export class ProjectAddressMeta extends LWLModel {}
export class ProjectAddressPoint extends LWLModel {
  static VERBOSE_NAME = "Adress-Punkt";
  static VERBOSE_NAME_PLURAL = "Adress-Punkte";
  static ENDPOINT = "addresspoint";
  static fromEmpty() {
    return null;
  }

  getMeta() {
    return this.meta.map((meta) => ProjectAddressMeta.fromAPI(meta));
  }

  static async fetchByIds(projectId, ids) {
    return fetchByIds(this, config.endpoints.PROJECTS, projectId, ids);
  }
}

export const ProjectMetaModule = new SimpleModuleGenerator(
  {
    addressPoints: ProjectAddressPoint,
  },
  {
    state: {
      currentAddressPoint: null,
    },
    mutations: {
      setCurrentAddressPoint(state, addressPoint) {
        state.currentAddressPoint = addressPoint;
      },
    },
    getters: {
      currentAddressPoint(state, getters) {
        return getters["addressPointsById"](state.currentAddressPoint);
      },
    },
    actions: {
      async setCurrentAddressPoint({ commit, dispatch, rootGetters }, payload) {
        if (payload === null) {
          commit("setCurrentAddressPoint", null);
        } else {
          commit("setCurrentAddressPoint", payload.id);
          let project = rootGetters["projects/project"].id;
          dispatch("fetchAddressPointsByIds", {
            projectId: project,
            ids: [payload.id],
          });
        }
      },
    },
  }
).module();
