import auth from "@/services/auth.service";
import config from "@/app.config";
import { useToast } from "vue-toastification";
import store from "@/store/index.js";
import { File } from "@/models/datapool";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
  fetchByIds,
} from "@/components/base/ModuleGenerator";
import { LWLModel } from "@/models/base";
import { HouseBox } from "@/models/network";

export default class Job extends LWLModel {
  static ENDPOINT = "jobs";
  static VERBOSE_NAME = "Auftrag";
  static VERBOSE_NAME_PLURAL = "Aufträge";

  constructor(obj) {
    super(obj);
  }

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }

  static async fetchByIds(projectId, ids) {
    return fetchByIds(this, config.endpoints.PROJECTS, projectId, ids);
  }

  static getStatusOptions() {
    let options = [
      {
        key: 1,
        label: "freigegeben",
        color: "#DF921E",
      },
      {
        key: 2,
        label: "angenommen",
        color: "#FFE500",
      },
      {
        key: 3,
        label: "abgeschlossen",
        color: "#A3E77F",
      },
      {
        key: 4,
        label: "abgenommen",
        color: "#217011",
      },
      {
        key: 99,
        label: "storniert",
        color: "#C02F2F",
      },
    ];
    return options;
  }

  static getInitialJobFilter() {
    return {
      dateRange: null,
      category: [],
      status: [],
      client: [],
      responsible: [],
      fundingType: [],
    };
  }

  static async createHouseBoxConnection(projectId, node) {
    let action = "house_box_connection";
    return Job.createJobAction(projectId, action, node);
  }

  static async createJobAction(projectId, action, node, url = null) {
    if (url === null) {
      url = `${config.endpoints.PROJECTS}${projectId}/${Job.ENDPOINT}/action/${action}/${node.id}/`;
    } else {
      url = `${config.BACKEND_PORTAL}${url}`;
    }
    return auth.get(url).then((response) => {
      return Job.from_api(response.data);
    });
  }

  prepareForApi() {
    let data = {
      title: this.title,
      description: this.description,
      due_date: this.due_date,
      status_id: this.status.value,
      is_system_generated: false,
      action_description_generated: true,
      job_client: this.job_client,
      category: this.category,
      detail_url: this.detail_url,
    };
    if (this.funding_type) data.funding_type = this.funding_type;
    if (this.action) data.action = this.action;
    if (this.service_provider) data.service_provider = this.service_provider;
    if (this.prefer_jobs) data.prefer_jobs = this.prefer_jobs;
    if (this.responsible) data.responsible = this.responsible;
    if (this.files) data.files = this.files.map((f) => f.id);

    return data;
  }

  updateOrCreate(projectId) {
    if (!this.id) {
      return this.create(projectId);
    } else {
      return this.update();
    }
  }

  create(projectId) {
    return store
      .dispatch("jobs/createJob", {
        projectId,
        payload: this.prepareForApi(),
      })
      .then((response) => {
        //store.dispatch("jobs/setCurrentJobFilter", {});
        return response;
      });
  }

  update() {
    return store
      .dispatch("jobs/patchJobDetails", this.prepareForApi())
      .then((response) => {
        // fetch house box and update state after creating or
        // updating job with action name "house_box_connection"
        if (response.action?.name === "house_box_connection") {
          HouseBox.fetchByIdAndUpdateState(response.action.reference.id);
        }
        return response;
      });
  }

  toString() {
    let data = [this.number];
    if (this.title && this.title !== null) data.push(this.title);
    return data.join(" - ");
  }

  getFullPdfUrl() {
    return `${config.BACKEND_PORTAL}${this.pdf_url}`;
  }

  getStatus() {
    return this.getStatusOption().label;
  }

  getStatusOption() {
    return Job.getStatusOptions().find(
      (option) => option.key == this.status.value
    );
  }

  getStatusColor() {
    return this.getStatusOption().color;
  }

  getOwner() {
    if (!this.owner) return null;
    const owner = store.getters["accounts/usersById"](this.owner);
    return owner?.id !== -1 ? owner : null;
  }

  getClient() {
    if (!this.job_client) return null;
    return store.getters["accounts/clientsById"](this.job_client);
  }

  getResponsibleUser() {
    if (!this.responsible) return null;
    return store.getters["accounts/usersById"](this.responsible);
  }

  getServiceProvider() {
    if (!this.service_provider) return null;
    return store.getters["providers/serviceProvidersById"](
      this.service_provider
    );
  }

  getCategory() {
    if (!this.category) return null;
    return store.getters["jobs/jobCategoriesById"](this.category);
  }

  // Sort meta data by order
  getSortedMetaData() {
    if (!this.action?.meta_data) return {};
    const valuesArray = Object.values(this.action.meta_data);
    valuesArray.sort((a, b) => a.order - b.order);
    return valuesArray.reduce((acc, item) => {
      acc[item.key] = item;
      return acc;
    }, {});
  }

  getComments() {
    if (!this.comments.length) return [];
    return this.comments.map((comment) => JobComment.from_api(comment));
  }

  getFundingType() {
    if (!this.funding_type) return null;
    return store.getters["jobs/jobFundingTypesById"](this.funding_type);
  }

  getFiles() {
    if (!this.files.length) return [];
    return this.files.map((file) => File.from_api(file));
  }

  getPreferJobs() {
    if (!this.prefer_jobs.length > 0) return [];
    return store.getters["jobs/jobsByIds"](this.prefer_jobs);
  }
}

export class JobCategory extends LWLModel {
  static ENDPOINT = "jobs-categories";
  static VERBOSE_NAME = "Kategorie";
  static VERBOSE_NAME_PLURAL = "Kategorien";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }

  static fetchMetaFields(project_id, category_id) {
    const url = `${config.endpoints.PROJECTS}${project_id}/${Job.ENDPOINT}/meta-fields/${category_id}/`;
    return auth.get(url).then((response) => {
      return response.data;
    });
  }

  get fileGroups() {
    if (
      !this.file_group_configuration &&
      !this.file_group_configuration?.file_groups
    )
      return [];
    return this.file_group_configuration.file_groups;
  }
}

export class JobComment extends LWLModel {
  static ENDPOINT = "jobs-comments";
  static VERBOSE_NAME = "Kommentar";
  static VERBOSE_NAME_PLURAL = "Kommentare";

  getOwner() {
    if (!this.owner) return null;
    const owner = store.getters["accounts/usersById"](this.owner);
    return owner?.id !== -1 ? owner : null;
  }
}

export class JobFundingType extends LWLModel {
  static ENDPOINT = "jobs-fundingtypes";
  static VERBOSE_NAME = "Förderungstyp";
  static VERBOSE_NAME_PLURAL = "Förderungstypen";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }
}

export const NewJobsStoreModule = new SimpleModuleGenerator(
  {
    jobFundingTypes: JobFundingType,
    jobCategories: JobCategory,
    jobs: Job,
  },
  {
    state: {
      fetchedAllJobExportTypes: false,
      fetchingAllJobExportTypes: false,
      jobExportTypes: [],
      currentJobFilter: Job.getInitialJobFilter(),
      currentJobPaginationPage: 1,
    },
    mutations: {
      setJobExportTypes(state, payload) {
        state.exportTypes = payload;
      },
      setResetJobExportTypes(state) {
        state.exportTypes = [];
        state.fetchAllJobExportTypes = false;
      },
      setFetchingAllJobExportTypes(state, payload) {
        state.fetchingAllJobExportTypes = payload;
      },
      setFetchedAllJobExportTypes(state, payload) {
        state.fetchedAllJobExportTypes = payload;
      },
      setCurrentJobFilter(state, payload) {
        state.currentJobFilter = payload;
      },
      setResetCurrentJobFilter(state) {
        state.currentJobFilter = Job.getInitialJobFilter();
      },
      setCurrentJobPaginationPage(state, payload) {
        state.currentJobPaginationPage = payload;
      },
      setResetCurrentJobPaginationPage(state) {
        state.currentJobPaginationPage = 1;
      },
    },
    actions: {
      resetExprotTypes: ({ commit }) => {
        commit("setResetJobExportTypes");
      },
      fetchAllJobExportTypes: ({ commit, getters }, payload) => {
        if (
          getters.fetchedAllJobExportTypes ||
          getters.fetchingAllJobExportTypes
        )
          return;

        commit("setFetchingAllJobExportTypes", true);
        let toast = useToast();
        let toastId = toast.info(`Lade Job Export Varianten...`, {
          timeout: false,
        });

        auth
          .get(
            `${config.endpoints.PROJECTS}${payload}/${Job.ENDPOINT}/jobs-export-types/`
          )
          .then(async (response) => {
            if (response.data.export_types.length !== 0) {
              commit("setJobExportTypes", response.data.export_types);
              commit("setFetchedAllJobExportTypes", true);
              commit("setFetchingAllJobExportTypes", false);
              toast.dismiss(toastId);
            }
          })
          .catch((error) => {
            console.error(error);
            commit("setFetchingAllJobExportTypes", false);
            toast.dismiss(toastId);
          });
      },
      resetJobExportTypes: ({ commit }) => {
        commit("setResetJobExportTypes");
      },
      setCurrentJobFilter({ commit }, filter) {
        commit("setCurrentJobFilter", filter);
      },
      resetCurrentJobFilter: ({ commit }) => {
        commit("setResetCurrentJobFilter");
      },
      setCurrentJobPaginationPage({ commit }, page) {
        commit("setCurrentJobPaginationPage", page);
      },
      resetCurrentJobPaginationPage: ({ commit }) => {
        commit("setResetCurrentJobPaginationPage");
      },
      async createJobComment({ commit }, { projectId, jobId, payload }) {
        const url = `${config.endpoints.PROJECTS}${projectId}/${Job.ENDPOINT}/${jobId}/job-add-comment/`;
        let result = await auth.post(url, payload).then((response) => {
          return Job.from_api(response.data);
        });
        commit("addJobs", result);
        return result;
      },
      async createJob({ commit }, { projectId, payload }) {
        const url = `${config.endpoints.PROJECTS}${projectId}/${Job.ENDPOINT}/`;
        let result = await auth.post(url, payload).then((response) => {
          return Job.from_api(response.data);
        });
        commit("addJobs", result);
        return result;
      },
    },
    getters: {
      fetchingAllJobExportTypes: (state) => state.fetchingAllJobExportTypes,
      fetchedAllJobExportTypes: (state) => state.fetchedAllJobExportTypes,
      exportTypes: (state) => state.exportTypes,
      plannedJobs: (state) =>
        state.jobs.filter((job) => job.is_system_generated),
      currentJobs: (state) =>
        state.jobs.filter((job) => !job.is_system_generated),
      jobByNumber: (state) => (number) => {
        return state.jobs.find((job) => job.number === number);
      },
      currentJobFilter: (state) => state.currentJobFilter,
      currentJobPaginationPage: (state) => state.currentJobPaginationPage,
    },
  }
).module();
