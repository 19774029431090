import moment from "moment";
import auth from "@/services/auth.service";
import config from "@/app.config";
import { useToast } from "vue-toastification";

const toast = useToast();

class ParentNotSetError extends Error {
  constructor(message, subject) {
    super(message);
    this.name = "ParentNotSetError";
    this.subject = subject;
  }
}

export class LWLModel {
  constructor(obj) {
    if (new.target === LWLModel) {
      throw new Error(
        `Abstract class ${new.target.name} can't be instantiated.`
      );
    } else {
      this._parent = undefined;
      this._has_details = undefined;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          this[key] = obj[key];
        }
      }
    }
  }
  static VERBOSE_NAME = "Daten";
  static VERBOSE_NAME_PLURAL = "Daten";

  static STATUS_PLANNED = 0;
  static STATUS_DELEGATED = 10;
  static STATUS_WORKING = 20;
  static STATUS_COMPLETED = 30;
  static STATUS_CHECK_REQUIRED = 40;

  static STATUS_PLANNED_COLOR = "#C02F2F";
  static STATUS_WORKING_COLOR = "#FFE500";
  static STATUS_COMPLETED_COLOR = "#217011";
  static STATUS_CHECK_REQUIRED_COLOR = "#DF921E";

  static ALL_STATUS_CHOICES = [
    [LWLModel.STATUS_PLANNED, "in Planung"],
    [LWLModel.STATUS_DELEGATED, "Beauftragt"],
    [LWLModel.STATUS_WORKING, "In Bearbeitung"],
    [LWLModel.STATUS_COMPLETED, "Abgeschlossen"],
    [LWLModel.STATUS_CHECK_REQUIRED, "Prüfung erforderlich"],
  ];

  static STATUS_CHOICES = [
    [LWLModel.STATUS_PLANNED, "in Planung", LWLModel.STATUS_PLANNED_COLOR],
    [LWLModel.STATUS_WORKING, "In Bearbeitung", LWLModel.STATUS_WORKING_COLOR],
    [
      LWLModel.STATUS_COMPLETED,
      "Abgeschlossen",
      LWLModel.STATUS_COMPLETED_COLOR,
    ],
    [
      LWLModel.STATUS_CHECK_REQUIRED,
      "Prüfung erforderlich",
      LWLModel.STATUS_CHECK_REQUIRED_COLOR,
    ],
  ];

  static SERVICE_LEVEL_UNDEFINED = 0;
  static SERVICE_LEVEL_PRIVATE = 10;
  static SERVICE_LEVEL_BUSINESS = 20;
  static SERVICE_LEVEL_DARK_FIBER = 30;

  static SERVICE_LEVEL_CHOICES = [
    [LWLModel.SERVICE_LEVEL_UNDEFINED, "Undefiniert"],
    [LWLModel.SERVICE_LEVEL_PRIVATE, "Privat"],
    [LWLModel.SERVICE_LEVEL_BUSINESS, "Business"],
    [LWLModel.SERVICE_LEVEL_DARK_FIBER, "Dark Fiber"],
  ];

  static from_api(data, parent, model) {
    let instance;
    if (model) instance = new model();
    else instance = new this();
    for (const [key, value] of Object.entries(data)) {
      instance[key] = value;
    }
    instance._parent = parent;
    instance._rawdata = data;
    instance._fetched = {}; // used to store data fetched by url
    instance._cached = {};
    return instance;
  }

  static fromAPI(data, parent, model) {
    //alias
    return this.from_api(data, parent, model);
  }

  static fromEmpty(title) {
    const data = {
      id: -1,
      title: title || "Lade ...",
    };
    return this.fromAPI(data);
  }

  cached(name, getter) {
    if (!this._cached[name]) {
      this._cached[name] = getter();
    }
    return this._cached[name];
  }

  static async fetchAll() {
    console.error(
      `NotImplemented: fetchAll for ${this.constructor.name} not implemented yet.`
    );
  }

  static async fetchDetails(data) {
    console.log(`fetchDetails for ${this.constructor.name}`, data);
    return auth
      .get(`${config.BACKEND_PORTAL}${data.detail_url}`)
      .then((response) => {
        return this.from_api(response.data);
      })
      .catch((error) => {
        let status = error.toJSON().status;
        if (status === 403) {
          toast.error("Fehlende Berechtigung.");
        } else {
          throw error;
        }
      });
  }

  static async patchDetails(data) {
    console.log(`patchDetails for ${this.constructor.name}`, data);
    let detail_url = data.detail_url;
    delete data.detail_url;
    return auth
      .patch(`${config.BACKEND_PORTAL}${detail_url}`, data)
      .then((response) => {
        return this.from_api(response.data);
      })
      .catch((error) => {
        throw error;
      });
  }

  static async delete(data) {
    console.log(`delete for ${this.constructor.name}`, data);
    let detail_url = data.detail_url;
    return auth
      .delete(`${config.BACKEND_PORTAL}${detail_url}`)
      .catch((error) => {
        let status = error.toJSON().status;
        if (status === 403) {
          toast.error("Fehlende Berechtigung.");
        } else {
          throw error;
        }
      });
  }

  getVerboseName() {
    return this.constructor.VERBOSE_NAME;
  }

  hasDetails() {
    return this._has_details;
  }

  get loaded() {
    return this.id > -1;
  }

  get parent() {
    if (!this._parent)
      throw new ParentNotSetError(
        `No parent set for instance ${this.uid}`,
        this
      );
    return this._parent;
  }

  get uid() {
    return `${this.constructor.name}-${this.id || this.pk}`.toLowerCase();
  }

  getCreated(format) {
    return moment(this.created).format(format || "LLL");
  }

  getModified(format) {
    return moment(this.modified).format(format || "LLL");
  }

  toString() {
    return `${this.constructor.name} ${this.id}`;
  }

  getStatusColor() {
    let status = LWLModel.STATUS_CHOICES.find(
      ([value, text, color]) => value === this.status.id
    );
    return status ? status[2] : null;
  }
}
